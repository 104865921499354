<template>
    <el-dropdown @command="handleCommand" trigger="click">
  <span class="el-dropdown-link">
    <span class="link">{{title}}</span><i class="el-icon-arrow-down el-icon--right"></i>
  </span>
  <el-dropdown-menu slot="dropdown">
    <el-dropdown-item :command="index" v-for="(item,index) in data" :key="index">{{item.name}}</el-dropdown-item>
  </el-dropdown-menu>
</el-dropdown>
</template>
<script>
export default {
    props:{
        value:String | Number,
        text:String,
        data:Array
    },
    data(){
        return {
          title:''
        }
    },
    mounted(){
        this.title=this.text;
    },
    methods:{
        handleCommand(index){
          let a=this.data[index];
            this.title=a.name;
            this.$emit('input',a.value);
            this.$emit('change',a.value);
        }
    }
}
</script>
<style scoped>
.link{
  cursor: pointer;
  color: #898989;
}
</style>
<template>
<div class="cu-btn" 
:class="plain?'plain':'bg-blue'"
:style="'font-size:'+fontSize+'px ;'+(width>0?'width:'+width+'px;text-align:center;':'padding:0 '+paddingLr+'px;')+'height:'+height+'px;line-height:'+height+'px;'
+(color!=''?'background:'+color+';':'')

">
<slot></slot>{{text}}
</div>
</template>
<script>
export default {
    props:{
        text:String,
        fontSize:{type:Number,default:14},
        height:{type:Number,default:40},
        width:{type:Number,default:0},
        paddingLr:{type:Number,default:16},
        plain:{type:Boolean,default:false},
        color:{type:String,default:''},
    }
}
</script>
<style lang="scss" scoped>
.cu-btn{
    display: inline-block;
   border: 1px solid #0096FF;
   cursor: pointer;
}
.plain{
    color: #0096FF;
}
.bg-blue{
    background: #0096FF;
    color: #FFF;
}
</style>